.assessment-wrapper {

  &__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  &__radio {

    &:last-child {
      margin-left: 10px;
    }

  }
}
