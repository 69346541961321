.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/login/Ambulanz_colored.jpg");

  &__alert {
    margin-bottom: 40px;
    text-align: center;
  }

  &__form {
    width: 400px;
  }
}
