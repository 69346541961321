@import "fonts";
@import "variables";

.app {
  font-family: $text-font;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  &__layout {
    min-height: 100vh;
  }

  &__content {
    height: 100vh;
    overflow: auto;
  }
}

.centered-spinner.ant-spin {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
