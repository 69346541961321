@import "../../../variables";
$cardboard-header-height: 150px;
$cardboard-padding: 3vw;

.card-board {

  &__header {
    height: $cardboard-header-height;
    padding: 0;
    background: $white;
  }

  &__title {
    text-align: left;
    font-size: 24px;
    color: $dark;
    margin: 45px $cardboard-padding 0;
    padding: 0;
    line-height: normal;
  }

  &__description {
    text-align: left;
    font-size: 14px;
    color: $dark;
    margin: 7px $cardboard-padding 0;
    padding: 0;
    line-height: normal;
  }

  &__content {
    min-height: calc(100vh - #{$cardboard-header-height});
    padding: 40px $cardboard-padding 20px;
    background-color: $pale-grey;
  }

  &__add-button {
    margin-bottom: 10px;
    margin-right: 10px;
    width: 215px;
    height: 40px;
  }

  &__empty-logo {
    width: 400px;
    height: 405px;
  }

  &__empty-apology {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.94;
    color: $dark-royal-blue;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
