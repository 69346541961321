@import "../../variables";

.operation-form {

  &__input {
    //border: none;
  }

  &__legend {
    text-align: center;
    margin-bottom: 15px;
  }

  &__row-title {
    line-height: 40px;
  }

  &__slider {
    padding-left: 15px;
    width: 70%;
    margin-top: 35px;
  }

  &__alert {
    height: 50px;
    position: relative;
    width: 100%;

    .ant-alert {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  &__print-spin {
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-explain {
    display: none;
  }

  .ant-input-number {
    width: 100%;

    &-input {
      padding: 0;
      text-align: center;
    }
  }

  .ant-input-number-disabled {

    .ant-input-number-input {
      color: $text-color;
    }
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-form-item-label > label {
    color: $text-color;
  }

  textarea.ant-input.ant-input-disabled {
    color: $text-color;
  }
}
