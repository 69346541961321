/* stylelint-disable sh-waqar/declaration-use-variable */
@font-face {
  font-family: Nunito;
  src: url("fonts/Nunito/Nunito-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Nunito;
  src: url("fonts/Nunito/Nunito-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Nunito;
  src: url("fonts/Nunito/Nunito-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}
/* stylelint-enable sh-waqar/declaration-use-variable */
