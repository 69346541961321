@import "../../variables";

.navigation {
  background-color: $ocean-blue;
  width: 350px;
  height: 100vh;
  color: $white;
  padding: 0 50px;
  overflow: auto;

  &__children {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-bottom: 40px;
  }

  &--no-user {
    width: 0;
  }

  &__logo {
    margin-top: 45px;
    margin-bottom: 25px;
    height: auto;
    max-width: 100%;
  }

  &__info {
    margin-bottom: 40px;

    .ant-typography {
      color: $secondaryLight;
      font-size: 14px;
      line-height: 2em;
    }
  }

  &__user-data {
    font-size: 16px;
    margin-top: 10px;
  }

  &__change-password {
    margin-top: auto;

    &__button {
      padding: 0;
      font-size: 16px;
      font-weight: bold;
      color: $white;
      transition: color 0.3s;

      &:focus {
        color: $white;
      }

      &:hover,&:focus:hover {
        color: $link-hover;
      }
    }
  }

  &__link {
    margin-bottom: 40px;

    &__label {
      font-size: 16px;
      font-weight: bold;
      color: $white;

      &--active {
        font-size: 16px;
        font-weight: bold;
        color: $orangey-yellow;
      }
    }
  }

  &__collapsible {
    padding-left: 20px;

    .navigation__link__label {
      font-weight: normal;
    }
  }
}
