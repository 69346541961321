@import "../../variables";

.location-card {
  text-align: left;
  width: 250px;
  height: 300px;

  &:hover {
    box-shadow: 0 2px 6px 6px $ocean-blue;
  }

  &--with-button {

    .ant-card-body {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__ellipsis {
    color: $dark;
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
    color: $dark;
    margin-bottom: 10px;
  }

  &__address {
    font-size: 12px;
    color: $secondary;
    margin-top: 10px;
  }

  &__actions {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &--horizontal {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__link {
    font-size: 12px;
    color: $dark;
    padding: 0;

    span {
      text-decoration-line: underline;
    }
  }

  &__open-button-container {
    align-self: flex-end;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &__description {
    line-height: 1.5;
    max-height: 9em;
    overflow: hidden;
    max-width: 200px;
    display: inline-block;
  }

  .ant-card-body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .ant-tag {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
