/* stylelint-disable color-no-hex */

$pale-grey: #fafaff;
$orangey-yellow: #f5a623;
$ocean-blue: #0752a5;
$green-blue: #00b18a;
$dark-royal-blue: #001f7d;
$strawberry: #f62337;
$secondary: #9b9b9b;
$secondaryLight: lighten($secondary, 15);
$dark: #1b1c2c;
$white: #ffffff;
$text-color: rgba(0, 0, 0, 0.65);

$link-hover: #40a9ff;

/* stylelint-enable */

$text-font: Nunito, sans-serif;
$nav-font: Montserrat, sans-serif;
